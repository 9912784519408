export enum Status {
  loggedOut,
  basic,
  premium,
}

export class AuthStatus {
  static status = (): Status => {
    const dom = document.getElementById('data_id');
    if (dom?.dataset['auth'] == 'false') {
      return Status.loggedOut;
    } else if (dom?.dataset['tier'] === '1') {
      return Status.premium;
    }
    return Status.basic;
  }
}
